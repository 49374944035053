import React from "react";

const InfoIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="19.9219"
         height="19.9316">
      <g>
        <rect height="19.9316" opacity="0" width="19.9219" x="0" y="0"/>
        <path
          d="M9.96094 19.9219C15.4102 19.9219 19.9219 15.4004 19.9219 9.96094C19.9219 4.51172 15.4004 0 9.95117 0C4.51172 0 0 4.51172 0 9.96094C0 15.4004 4.52148 19.9219 9.96094 19.9219ZM8.20312 15.6152C7.7832 15.6152 7.46094 15.3125 7.46094 14.8926C7.46094 14.502 7.7832 14.1797 8.20312 14.1797L9.44336 14.1797L9.44336 9.58984L8.36914 9.58984C7.95898 9.58984 7.63672 9.28711 7.63672 8.86719C7.63672 8.47656 7.95898 8.1543 8.36914 8.1543L10.2637 8.1543C10.7812 8.1543 11.0547 8.52539 11.0547 9.07227L11.0547 14.1797L12.2949 14.1797C12.7148 14.1797 13.0371 14.502 13.0371 14.8926C13.0371 15.3125 12.7148 15.6152 12.2949 15.6152ZM9.87305 6.49414C9.14062 6.49414 8.55469 5.9082 8.55469 5.16602C8.55469 4.42383 9.14062 3.83789 9.87305 3.83789C10.6055 3.83789 11.1816 4.42383 11.1816 5.16602C11.1816 5.9082 10.6055 6.49414 9.87305 6.49414Z"
          fill="#a16207"/>
      </g>
    </svg>
  )
}

export default InfoIcon;
