import React from "react";

const GroupIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38.9648"
         height="15">
      <g>
        <rect height="18.4082" opacity="0" width="38.9648" x="0" y="0"/>
        <path
          d="M2.39258 18.4082L14.668 18.4082C16.2891 18.4082 17.0605 17.9199 17.0605 16.8457C17.0605 14.2871 13.8281 10.5859 8.53516 10.5859C3.23242 10.5859 0 14.2871 0 16.8457C0 17.9199 0.771484 18.4082 2.39258 18.4082ZM1.93359 16.9336C1.67969 16.9336 1.57227 16.8652 1.57227 16.6602C1.57227 15.0586 4.05273 12.0605 8.53516 12.0605C13.0078 12.0605 15.4883 15.0586 15.4883 16.6602C15.4883 16.8652 15.3906 16.9336 15.1367 16.9336ZM8.53516 9.23828C10.8496 9.23828 12.7539 7.16797 12.7539 4.64844C12.7539 2.1582 10.8789 0.185547 8.53516 0.185547C6.21094 0.185547 4.30664 2.1875 4.31641 4.66797C4.31641 7.17773 6.21094 9.23828 8.53516 9.23828ZM8.53516 7.76367C7.09961 7.76367 5.88867 6.39648 5.88867 4.66797C5.87891 2.96875 7.08008 1.66016 8.53516 1.66016C9.99023 1.66016 11.1816 2.94922 11.1816 4.64844C11.1816 6.37695 9.9707 7.76367 8.53516 7.76367Z"
          fill="#000000"/>
        <path
          d="M19.4727 10.5859C17.8418 10.5859 16.4062 10.9473 15.1953 11.5137C15.6445 11.8555 16.0645 12.2168 16.4258 12.6074C17.2949 12.2754 18.3203 12.0605 19.4727 12.0605C23.9551 12.0605 26.4355 15.0586 26.4355 16.6602C26.4355 16.8652 26.3281 16.9336 26.0742 16.9336L18.2715 16.9336C18.2617 17.4609 18.1641 17.959 17.8516 18.4082L25.6055 18.4082C27.2363 18.4082 28.0078 17.9199 28.0078 16.8457C28.0078 14.2871 24.7754 10.5859 19.4727 10.5859ZM19.4824 9.23828C21.8066 9.23828 23.7012 7.16797 23.7012 4.64844C23.7012 2.1582 21.8262 0.185547 19.4824 0.185547C17.1582 0.185547 15.2539 2.1875 15.2637 4.66797C15.2734 7.17773 17.1582 9.23828 19.4824 9.23828ZM19.4824 7.76367C18.0566 7.76367 16.8359 6.39648 16.8359 4.66797C16.8262 2.96875 18.0273 1.66016 19.4824 1.66016C20.9375 1.66016 22.1289 2.94922 22.1289 4.64844C22.1289 6.37695 20.918 7.76367 19.4824 7.76367Z"
          fill="#000000" fillOpacity="0.5"/>
        <path
          d="M30.4297 10.5859C28.7988 10.5859 27.3535 10.9473 26.1426 11.5234C26.6016 11.8555 27.0117 12.2168 27.373 12.627C28.2422 12.2754 29.2773 12.0605 30.4297 12.0605C34.9121 12.0605 37.3926 15.0586 37.3926 16.6602C37.3926 16.8652 37.2852 16.9336 37.0312 16.9336L29.2188 16.9336C29.209 17.4609 29.1113 17.959 28.7891 18.4082L36.5625 18.4082C38.1934 18.4082 38.9648 17.9199 38.9648 16.8457C38.9648 14.2871 35.7324 10.5859 30.4297 10.5859ZM30.4297 9.23828C32.7539 9.23828 34.6484 7.16797 34.6484 4.64844C34.6484 2.1582 32.7734 0.185547 30.4297 0.185547C28.1152 0.185547 26.2109 2.1875 26.2109 4.66797C26.2207 7.17773 28.1152 9.23828 30.4297 9.23828ZM30.4297 7.76367C29.0039 7.76367 27.7832 6.39648 27.7832 4.66797C27.7832 2.96875 28.9844 1.66016 30.4297 1.66016C31.8848 1.66016 33.0762 2.94922 33.0762 4.64844C33.0762 6.37695 31.875 7.76367 30.4297 7.76367Z"
          fill="#000000" fillOpacity="0.18"/>
      </g>
    </svg>
  )
}

export default GroupIcon;
