import React from 'react';
import CourseSearch from "../CourseSearch";

const CourseFullList = () => {


  return (
    <div className="container mx-auto width mt-[4.5rem]">
      <CourseSearch contentSize={"big"}/>
    </div>
  )
}


export default CourseFullList
