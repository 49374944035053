import React from "react";

const RepeatIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15.4859"
         height="15.4956">
      <g>
        <rect height="15.4956" opacity="0" width="15.4859" x="0" y="0"/>
        <path
        d="M0.6,9.3C1,9.3,1.2,9,1.2,8.7V8c0-1.2,0.8-2.1,2.2-2.1h5.7v1.7c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.3-0.1,0.4-0.1
        l2.7-2.3c0.2-0.2,0.2-0.5,0-0.7L9.9,2.7C9.8,2.6,9.7,2.6,9.5,2.6C9.2,2.6,9.1,2.7,9.1,3v1.7H3.5C1.4,4.7,0,5.9,0,7.9v0.8
        C0,9,0.3,9.3,0.6,9.3z M14.9,8.8c-0.4,0-0.6,0.3-0.6,0.6v0.7c0,1.2-0.8,2.1-2.2,2.1H6.4v-1.7C6.4,10.1,6.3,10,6,10
        c-0.1,0-0.3,0-0.4,0.1l-2.7,2.3c-0.2,0.2-0.2,0.5,0,0.7l2.7,2.3c0.1,0.1,0.2,0.1,0.4,0.1c0.3,0,0.5-0.2,0.5-0.5v-1.7H12
        c2.1,0,3.5-1.2,3.5-3.2V9.4C15.5,9,15.2,8.8,14.9,8.8z"/>
      </g>
    </svg>
  )
}

export default RepeatIcon;
