import React from "react";

const CalendarIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15.4859"
         height="15.4956">
      <g>
        <rect height="17.998" opacity="0" width="19.4629" x="0" y="0"/>
        <path className="st1" d="M2.4,15.5h10.6c1.6,0,2.4-0.8,2.4-2.4V3.6c0-1.6-0.8-2.4-2.4-2.4H2.4C0.8,1.2,0,2,0,3.6v9.5
		C0,14.7,0.8,15.5,2.4,15.5z M2.3,14.2c-0.7,0-1.1-0.4-1.1-1.1V5.8c0-0.7,0.4-1.1,1.1-1.1h10.8c0.7,0,1.1,0.4,1.1,1.1v7.3
		c0,0.7-0.4,1.1-1.1,1.1H2.3z M6.2,7.5h0.5C7,7.5,7,7.4,7,7.2V6.7C7,6.4,7,6.3,6.7,6.3H6.2C6,6.3,5.9,6.4,5.9,6.7v0.5
		C5.9,7.4,6,7.5,6.2,7.5z M8.8,7.5h0.5c0.3,0,0.4-0.1,0.4-0.3V6.7c0-0.3-0.1-0.4-0.4-0.4H8.8c-0.3,0-0.4,0.1-0.4,0.4v0.5
		C8.4,7.4,8.5,7.5,8.8,7.5z M11.4,7.5h0.5c0.3,0,0.4-0.1,0.4-0.3V6.7c0-0.3-0.1-0.4-0.4-0.4h-0.5c-0.3,0-0.4,0.1-0.4,0.4v0.5
		C11,7.4,11.1,7.5,11.4,7.5z M3.7,10.1h0.5c0.3,0,0.4-0.1,0.4-0.3V9.2c0-0.3-0.1-0.3-0.4-0.3H3.7C3.4,8.9,3.3,9,3.3,9.2v0.5
		C3.3,10,3.4,10.1,3.7,10.1z M6.2,10.1h0.5C7,10.1,7,10,7,9.7V9.2C7,9,7,8.9,6.7,8.9H6.2C6,8.9,5.9,9,5.9,9.2v0.5
		C5.9,10,6,10.1,6.2,10.1z M8.8,10.1h0.5c0.3,0,0.4-0.1,0.4-0.3V9.2c0-0.3-0.1-0.3-0.4-0.3H8.8C8.5,8.9,8.4,9,8.4,9.2v0.5
		C8.4,10,8.5,10.1,8.8,10.1z M11.4,10.1h0.5c0.3,0,0.4-0.1,0.4-0.3V9.2c0-0.3-0.1-0.3-0.4-0.3h-0.5C11.1,8.9,11,9,11,9.2v0.5
		C11,10,11.1,10.1,11.4,10.1z M3.7,12.6h0.5c0.3,0,0.4-0.1,0.4-0.4v-0.5c0-0.3-0.1-0.3-0.4-0.3H3.7c-0.3,0-0.4,0.1-0.4,0.3v0.5
		C3.3,12.5,3.4,12.6,3.7,12.6z M6.2,12.6h0.5C7,12.6,7,12.5,7,12.2v-0.5c0-0.3-0.1-0.3-0.4-0.3H6.2c-0.3,0-0.4,0.1-0.4,0.3v0.5
		C5.9,12.5,6,12.6,6.2,12.6z M8.8,12.6h0.5c0.3,0,0.4-0.1,0.4-0.4v-0.5c0-0.3-0.1-0.3-0.4-0.3H8.8c-0.3,0-0.4,0.1-0.4,0.3v0.5
		C8.4,12.5,8.5,12.6,8.8,12.6z"/>
      </g>
    </svg>
  )
}

export default CalendarIcon;
